<template>
  <Popup ref="popupRef" width="500px" title="添加备注" confirm-text="提交" @confirm="confirm" @close="resetForm">
    <el-input
      type="textarea"
      :rows="8"
      :maxlength="150"
      show-word-limit
      placeholder="请输入备注内容"
      v-model="inputValue">
    </el-input>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { intentionMangeApi } from '@/api/admin.js'
export default {
  components: {
    Popup
  },
  data() {
    return {
      uid: '',
      inputValue: '',
      status: ''
    }
  },
  methods: {
    open(option) {
      if(!option.detail) return
      this.uid = option.detail.uid
      this.inputValue = option.detail.notes || ''
      this.status = option.detail.status
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetForm() {
      this.inputValue = ''
    },
    confirm() {
      if(!this.inputValue.trim()) return this.$tips({message: '请输入备注内容', type:'warning'})
      const formData = {
        act: 1,
        uid: this.uid,
        notes: this.inputValue.trim(),
        status: this.status
      }
      console.log('formData', formData)
      intentionMangeApi(formData).then(res => {
        this.$tips({message:res.msg, type:'success'})
        this.$emit('update')
        this.close()
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    }
  }
}
</script>

<style>

</style>